<template>
	<div>
		<a-divider orientation="left" class="divider">
			Horários de funcionamento
		</a-divider>
		<p>Informe em quais dias e horários vocês estarão em funcionamento</p>
		<a-row class="header" type="flex" justify="end">
			<a-button class="buttons" icon="delete" @click="onClean">
				Limpar
			</a-button>
		</a-row>
		<a-row>
			<WeeklyScheduler
				:data="intervals"
				@add="onAdd"
				@edit="onEdit"
				@delete="onDelete"
				@replicate="onReplicate"
			/>
		</a-row>
		<a-row
			v-if="edited"
			type="flex"
			justify="center"
			style="margin-bottom: 40px;"
		>
			<a-button
				class="buttons"
				icon="save"
				type="primary"
				style="width: 220px;"
				:loading="loading"
				@click="onSave"
			>
				Salvar alterações
			</a-button>
		</a-row>
		<a-divider orientation="left" class="divider">
			Fechamento programado
		</a-divider>
		<p>Indique datas em que o restaurante não irá abrir</p>
		<ScheduledClosing />
	</div>
</template>

<script>
import { ScheduledClosing } from './components'
import WeeklyScheduler from '../../components/vws'
import { merchantService } from '../../services'
import { mapGetters } from 'vuex'

export default {
	name: '',
	components: {
		WeeklyScheduler,
		ScheduledClosing,
	},
	data() {
		return {
			intervals: [],
			edited: false,
			loading: false,
		}
	},
	computed: {
		...mapGetters({
			selectedMerchantId: 'merchants/selectedMerchantId',
		}),
	},
	async beforeMount() {
		this.intervals = await merchantService.getIntervals(
			this.selectedMerchantId
		)
	},
	methods: {
		onAdd(payload) {
			const index = this.intervals.findIndex(
				(interval) => interval.day === payload.day
			)

			if (index > -1) this.intervals[index].periods.push(payload.period)
			else
				this.intervals.push({
					day: payload.day,
					periods: [payload.period],
				})

			this.edited = true
		},
		onDelete(payload) {
			const index = this.intervals.findIndex(
				(interval) => interval.day === payload.day
			)
			if (index > -1) {
				this.intervals[index].periods = this.intervals[
					index
				].periods.filter(
					(period) => period[0] + ' - ' + period[1] !== payload.period
				)

				this.edited = true
			}
		},
		async onEdit(data) {
			const index = this.intervals.findIndex(
				(interval) => interval.day === data.day
			)
			if (index > -1) {
				this.intervals[index].periods = this.intervals[
					index
				].periods.filter(
					(period) =>
						period[0] + ' - ' + period[1] !== data.payload.title
				)
				this.intervals[index].periods.push(data.payload.newPeriod)
				this.edited = true
			}
		},
		onReplicate(title) {
			let newPeriod = title.replace(/\s/g, '').split('-')

			for (let day = 0; day < 7; day++) {
				let objDay = this.intervals.find(
					(interval) => interval.day === day
				)
				if (objDay) {
					if (this.isValid(objDay.periods, newPeriod)) {
						objDay.periods.push(newPeriod)
						this.edited = true
					}
				} else {
					this.intervals.push({
						day: day,
						periods: [newPeriod],
					})
					this.edited = true
				}
			}
		},
		isValid(periods, newPeriod) {
			let valid = true
			let endTime = newPeriod[1] === '00:00' ? '24:00' : newPeriod[1]

			periods.forEach((period) => {
				let final = period[1] === '00:00' ? '24:00' : period[1]
				if (period[0] > newPeriod[0] && period[0] < endTime) {
					valid = false
				}

				if (final > newPeriod[0] && final < endTime) {
					valid = false
				}

				if (period[0] < newPeriod[0] && final > endTime) {
					valid = false
				}

				if (period[0] === newPeriod[0] || final === endTime) {
					valid = false
				}
			})

			return valid
		},
		async onSave() {
			try {
				this.loading = true

				const payload = {
					merchantId: this.selectedMerchantId,
					intervals: this.intervals,
				}

				await merchantService.setIntervals(payload)
				this.edited = false
			} finally {
				this.loading = false
			}
		},
		onClean() {
			this.intervals.forEach((interval) => {
				if (interval.periods.length > 0) {
					interval.periods = []
					this.edited = true
				}
			})
		},
	},
}
</script>

<style lang="less" scoped>
.header {
	margin: -40px 15px 15px 0px;
	border-bottom: 1px solid hsv(0, 0, 85%);
	height: 40px;

	.buttons {
		margin-left: 10px;
		float: right;
	}
}
</style>
